import React, { Component } from "react";
import styles from "./grid.module.css"
import GridItem from "./grid_item"

class Grid extends Component {
    items() {
        let items = []
        this.props.children.forEach((child, index) => {
            items.push(<GridItem key={index}>
                {child}
            </GridItem>)
        })
        return items;
    }

    render() {
        return <div className={styles.grid}>
            {this.items()}
        </div>
    }
}

export default Grid