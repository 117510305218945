import React, { Component } from "react"
import { graphql } from "gatsby"
import TeamMember from "../components/team_member"
import Grid from "../components/grid"

class Team extends Component {

  teamMembers() {
    let members = []
    
    this.props.data.allMarkdownRemark.edges.forEach(({ node }) => {
      members.push(
        <TeamMember
          key={node.id}
          name={node.frontmatter.name}
          description={node.html}
          email={node.frontmatter.email}
          avatarImage={node.frontmatter.img}
        />
      )
    })

    return members
  }

  render() {
    return (
      <div className="main-content">
        <Grid>
          {this.teamMembers()}
        </Grid>
      </div>

    )
  }
}

export default Team

export const pageQuery = graphql`
    query teamQuery {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/team_members/"}}) {
        edges {
          node {
            id
            frontmatter {
              name
              type
              email
              img {
                childImageSharp {
                  fixed(height: 120) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            html
          }
        }
      }
    }
`
