import React, { Component } from "react";
import styles from "./grid.module.css"

class GridItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false
        }
    }

    toggleExpand() {
        this.setState(
            {
                expanded: !this.state.expanded
            }
        )
    }

    classes() {
        let classes = styles.gridItem
        if (this.state.expanded) {
            classes += " "
            classes += styles.gridItemExpanded
        }
        return classes
    }

    render() {
        const childrenWithProps = React.Children.map(this.props.children, child =>
            React.cloneElement(child, { onToggleExpand: this.toggleExpand.bind(this) })
        );

        return <div className={this.classes()}>
            {childrenWithProps}
        </div>
    }
}

export default GridItem