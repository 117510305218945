import React, { Component } from "react"
import styles from "./team_member.module.css"
import Img from "gatsby-image"

class TeamMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
  }

  toggleExpand() {
    this.setState(
      {
        expanded: !this.state.expanded
      }
    )
    this.props.onToggleExpand()
  }

  classes() {
    let classes = styles.teamMember
    classes += " card"
    if (this.state.expanded) {
      classes += " "
      classes += styles.teamMemberExpanded
    }
    return classes
  }

  render() {
    return (
      <div className={this.classes()} onClick={this.toggleExpand.bind(this)}>
        <Img
          fixed={this.props.avatarImage.childImageSharp.fixed}
          className={styles.avatar}
        />
        <h2 className={styles.name} dangerouslySetInnerHTML={{ __html: this.props.name }} />

        <div className={styles.description}>
          <a className={styles.email} href={"mailto:" + this.props.email}>{this.props.email}</a>
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: this.props.description }} />
        </div>
      </div>
    )
  }
}
export default TeamMember
